<template>
  <div id="user-list">
    <v-card>
      <v-card-title>
        {{ t('tooltip.filters') }}
      </v-card-title>

      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-select
            v-model="roleFilter"
            :placeholder="t('select.role')"
            :items="rolesOptions"
            item-text="name"
            item-value="id"
            outlined
            clearable
            dense
            hide-details
            @change="updateFilter('views-user-list', 'role', roleFilter)"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-select
            v-model="statusFilter"
            :placeholder="t('select.status')"
            :items="statusOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            clearable
            hide-details
            @change="updateFilter('views-user-list', 'status', statusFilter)"
          ></v-select>
        </v-col>
      </v-row>
      <v-divider />

      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="t('search')"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>

        <v-spacer />

        <v-col
          v-if="hasPermission(8)"
          cols="12"
          sm="2"
        >
          <v-btn
            color="secondary"
            block
            @click="onCreate"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
            {{ t('tooltip.add') }}
          </v-btn>
        </v-col>
      </v-row>
      <v-divider />

      <v-data-table
        v-model="selectedRows"
        :headers="computedTableColumns"
        :items="userListTable"
        item-key="username"
        :options.sync="options"
        :loading="loading"
        show-select
        :loading-text="t('table.loading_text')"
        :no-data-text="t('table.no_data')"
        :header-props="{sortByText: t('table.sort_by')}"
        :footer-props="{itemsPerPageText: t('table.item_page')}"
      >
        <template #[`item.fullname`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              color="primary"
              :class="item.fullname ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <span
                class="font-weight-medium"
              >{{ avatarText(item.fullname) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name : 'views-user-form', params: { id: item.username, dataParams: item, option: 3 } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.fullname }}
              </router-link>
              <small>@{{ item.username }}</small>
            </div>
          </div>
        </template>

        <template #[`item.role_type`]="{item}">
          <div class="d-flex align-center">
            <v-avatar
              size="30"
              :color="resolveUserRoleVariant(item.role_type)"
              :class="`v-avatar-light-bg ${resolveUserRoleVariant(item.role_type)}--text me-3`"
            >
              <v-icon
                size="18"
                :color="resolveUserRoleVariant(item.role_type)"
              >
                {{ resolveUserRoleIcon(item.role_type) }}
              </v-icon>
            </v-avatar>
            <span class="text-capitalize">{{ resolveUserRoleText(item.role_type) }}</span>
          </div>
        </template>

        <template #[`item.group_name`]="{item}">
          <span class="text-capitalize font-weight-semibold text--primary">{{ item.group_name }}</span>
        </template>

        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveUserStatusVariant(item.status)"
            :class="`${resolveUserStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ resolveUserStatusText(item.status) }}
          </v-chip>
        </template>

        <template #[`item.actions`]="{item}">
          <v-tooltip
            v-if="hasPermission(7)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="info"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onShow(item)"
              >
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </template>
            <span>{{ $t('tooltip.show') }}</span>
          </v-tooltip>

          <v-tooltip
            v-if="hasPermission(9)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="success"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onEdit(item)"
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </template>
            <span>{{ $t('tooltip.edit') }}</span>
          </v-tooltip>

          <v-tooltip
            v-if="hasPermission(10)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="error"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onDelete(item.username)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
            <span>{{ $t('tooltip.delete') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import {
  mdiPlus,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiEyeOutline,
} from '@mdi/js'
import { onMounted, ref, computed } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { success, error } from '@core/utils/toasted'
import { deleteUser, getRoles } from '@api'

// sidebar
import { avatarText } from '@core/utils/filter'
import Swal from 'sweetalert2'

import useUsersList from './useUsersList'

export default {
  setup() {
    const { router, route } = useRouter()

    const {
      userListTable,
      computedTableColumns,

      searchQuery,
      roleFilter,
      statusFilter,
      loading,
      options,
      filters,
      selectedRows,

      t,
      fetchUsers,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusText,
      resolveUserRoleText,
      hasPermission,
      setFilters,
      setOptions,
      updateFilter,
      getFilterByModule,
    } = useUsersList()

    const roleOptions = computed(() => [
      { title: t('roles.administrator'), value: 'A' },
      { title: t('roles.facility_owner'), value: 'F' },
      { title: t('roles.facility_representative'), value: 'R' },
    ])
    const rolesOptions = ref([])
    const statusOptions = computed(() => [
      { title: t('status.active'), value: 'A' },
      { title: t('status.inactive'), value: 'I' },
    ])

    const onCreate = () => {
      router.push({
        name: 'views-user-form',
        params: {
          option: 1,
          pageParams: options.value,
          filterParams: filters.value,
        },
      })
    }

    const onEdit = item => {
      router.push({
        name: 'views-user-form',
        params: {
          id: item.username,
          dataParams: item,
          option: 3,
          pageParams: options.value,
          filterParams: filters.value,
        },
      })
    }

    const onShow = item => {
      router.push({
        name: 'views-user-form',
        params: {
          id: item.username,
          dataParams: item,
          option: 2,
          pageParams: options.value,
          filterParams: filters.value,
        },
      })
    }

    const onDelete = async id => {
      Swal.fire({
        title: 'Estas seguro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Eliminar!',
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await deleteUser(id)
          if (response.ok) {
            success(response.message)
            fetchUsers()
          } else {
            error(response.message.text)
          }
        }
      })
    }

    onMounted(async () => {
      const { filterParams, pageParams } = route.value.params
      setOptions(pageParams)
      setFilters(filterParams)

      const roles = await getRoles()
      rolesOptions.value = roles.data

      const filtersStored = getFilterByModule('views-user-list')
      if (filtersStored.role) roleFilter.value = filtersStored.role
      if (filtersStored.status) statusFilter.value = filtersStored.status
      if (filtersStored.search) searchQuery.value = filtersStored.search

      await fetchUsers()
    })

    return {
      userListTable,
      computedTableColumns,
      searchQuery,
      roleFilter,
      statusFilter,
      roleOptions,
      rolesOptions,
      statusOptions,
      loading,
      options,
      filters,

      onCreate,
      onEdit,
      onShow,
      onDelete,
      selectedRows,
      avatarText,
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserStatusText,
      resolveUserRoleText,
      fetchUsers,
      hasPermission,
      setFilters,
      setOptions,
      updateFilter,

      // i18n
      t,

      // icons
      icons: {
        mdiDeleteOutline,
        mdiPlus,
        mdiEyeOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.export-excel {
  display: block;
}
</style>
