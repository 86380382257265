/* eslint-disable operator-linebreak */
import {
  mdiAccountOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline,
} from '@mdi/js'
import { ref, watch, computed } from '@vue/composition-api'
import { getUsers } from '@api'
import { useUtils } from '@core/libs/i18n'
import { isObjEmpty } from '@core/utils'
import usePermissions from '@core/utils/usePermissions'
import useFilters from '@core/utils/useFilters'

export default function useUsersList() {
  const userListTable = ref([])
  const { hasActionPermission, hasPermission } = usePermissions()
  const { t } = useUtils()
  const { updateFilter, getFilterByModule } = useFilters()

  const tableColumns = computed(() => [
    { text: t('users.user').toUpperCase(), value: 'fullname', show: true },
    { text: t('users.email').toUpperCase(), value: 'email', show: true },
    { text: t('roles.role').toUpperCase(), value: 'console_role_name', show: true },
    { text: t('status.status').toUpperCase(), value: 'status', show: true },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
      show: hasActionPermission(7, 9, 10),
    },
  ])
  const computedTableColumns = computed(() => tableColumns.value.filter(e => e.show))

  const searchQuery = ref('')
  const roleFilter = ref(null)
  const statusFilter = ref(null)
  const totalUserListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['fullname'],
    sortDesc: [true],
  })
  const filters = ref(null)
  const flag = ref(true)
  const hasOptionsFilter = ref(false)
  const timerSearch = ref(null)
  const userTotalLocal = ref([])
  const selectedRows = ref([])

  const filterUser = user =>
    // eslint-disable-next-line implicit-arrow-linebreak
    (user.username.toLowerCase().includes(searchQuery.value) || user.email.toLowerCase().includes(searchQuery.value)) &&
    user.console_role_id === (roleFilter.value || user.console_role_id) &&
    user.status === (statusFilter.value || user.status)

  // fetch data
  const fetchUsers = async () => {
    let responseUser = null
    try {
      responseUser = await getUsers()

      const filterOption = {}
      filters.value = null
      if (searchQuery.value) filterOption.search = searchQuery.value
      if (statusFilter.value) filterOption.status = statusFilter.value
      if (roleFilter.value) filterOption.role_id = roleFilter.value

      filters.value = filterOption

      const filteredData = ref([])
      filteredData.value = responseUser.data.filter(filterUser)
      userListTable.value = filteredData.value
      totalUserListTable.value = filteredData.value.length
      userTotalLocal.value = null
      loading.value = false
    } catch (e) {
      console.error(e)
      loading.value = false
    }
  }

  const setOptions = value => {
    flag.value = false
    if (value) {
      hasOptionsFilter.value = true
      options.value = value
    } else {
      hasOptionsFilter.value = false
    }
  }

  const setFilters = value => {
    flag.value = false
    if (value && !isObjEmpty(value)) {
      if (value.search) searchQuery.value = value.search
      if (value.status) statusFilter.value = value.status
      if (value.role_id) roleFilter.value = value.role_id
    }
  }

  const getDataBySearch = async (time = 1000) => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      await fetchUsers()
    }, time)
  }

  watch([searchQuery], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter.value) options.value.page = 1
    updateFilter('views-user-list', 'search', searchQuery.value)
    await getDataBySearch()
  })

  watch([options], async () => {
    if (flag.value) {
      loading.value = true
      await getDataBySearch(300)
    }
  })

  watch([roleFilter, statusFilter], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter.value) options.value.page = 1
    await getDataBySearch(300)
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber' || role === 'F') return 'info'
    if (role === 'author' || role === 'R') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin' || role === 'A') return 'error'

    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber' || role === 'F') return mdiAccountOutline
    if (role === 'author' || role === 'R') return mdiCogOutline
    if (role === 'maintainer') return mdiDatabaseOutline
    if (role === 'editor') return mdiPencilOutline
    if (role === 'admin' || role === 'A') return mdiDnsOutline

    return mdiAccountOutline
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active' || status === 'A') return 'success'
    if (status === 'inactive' || status === 'I') return 'error'

    return 'primary'
  }

  const resolveUserRoleText = role => {
    if (role === 'subscriber' || role === 'F') return t('roles.facility_owner')
    if (role === 'author' || role === 'R') return t('roles.facility_representative')
    if (role === 'maintainer') return 'Maintainer'
    if (role === 'editor') return 'Editor'
    if (role === 'admin' || role === 'A') return t('roles.administrator')

    return 'Administrator'
  }

  const resolveUserStatusText = status => {
    if (status === 'A') return t('status.active')
    if (status === 'I') return t('status.inactive')

    return t('status.active')
  }

  return {
    userListTable,
    computedTableColumns,
    searchQuery,
    roleFilter,
    statusFilter,
    totalUserListTable,
    loading,
    options,
    filters,
    userTotalLocal,
    selectedRows,

    t,
    fetchUsers,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    resolveUserStatusText,
    resolveUserRoleText,
    hasPermission,
    setFilters,
    setOptions,
    updateFilter,
    getFilterByModule,
  }
}
